/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   iconPack: false,
  //   svgIcon: "bell2.svg",
  //   i18n: "Dashboard"
  // },
  // {
  //   url: "/task",
  //   name: "Tareas",
  //   slug: "task",
  //   icon: "FileIcon",
  //   iconPack: false,
  //   svgIcon: "torre_control.png",
  //   i18n: "mapview.torredecontrol",
  // },
  // {
  //   url: null,
  //   name: "Resources",
  //   slug: "recurso",
  //   svgIcon: "30.svg",
  //   i18n: "recursos",
  //   submenu: [
  //     {
  //       url: '/vehicles',
  //       name: "vehicles",
  //       slug: "vehicles_grid",
  //       i18n: "vehicles.vehicles",
  //     },
      // {
      //   url: '/vehicle/types',
      //   name: "vehicle_types",
      //   slug: "vehicles_types_grid",
      //   i18n: "vehicles.vehicles_type",
      // },
      //{
      //  url: '/clients',
      //  name: "clients",
      //  slug: "clients_grid",
      //  i18n: "clients.clients",
      //},
      // {
      //   url: '/newness/types',
      //   name: "newnessTypes",
      //   slug: "newnessTypes",
      //   i18n: "newness.newness"
      // },
      // {
      //   url: '/liquidador',
      //   name: "Liquidador",
      //   slug: "Liquidador",
      //   i18n: "exportable_liquidador"
      // },
      // {
      //   url: '/pages/products',
      //   name: "Products",
      //   slug: "dashboardProducts",
      //   i18n: "product.name"
      // }
    // ]
  // }
]
