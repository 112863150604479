<template lang="pug">
  div(
    class="layout--main"
    :class="[navbarClasses, footerClasses, {'app-page': isAppPage}]"
  )
    the-customizer(
      v-if="!disableCustomizer"
      :navbarType="navbarType"
      :navbarColor="navbarColor"
      :footerType="footerType"
      :routerTransition="routerTransition"
      :hideScrollToTop="hideScrollToTop"
      @updateNavbar="updateNavbar"
      @updateNavbarColor="updateNavbarColor"
      @updateFooter="updateFooter"
      @updateRouterTransition="updateRouterTransition"
      @toggleHideScrollToTop="toggleHideScrollToTop"
    )
    vx-sidebar(
      :sidebarItems='sidebarItems'
      :logo="require('@/assets/images/logo/night-mode.png')"
      :logo_letras="require('@/assets/images/logo/logo_letras.png')"
      parent='.layout--main'
    )
    #content-area(
      :class="[contentAreaClass, {'show-overlay': bodyOverlay}]"
    )
      div.content-wrapper#navbar-map
        the-navbar(
          :navbarColor='navbarColor'
          :class="[{'text-white': isNavbarDark && !isThemeDark}, {'text-base': !isNavbarDark && isThemeDark}]"
        )
      router-view
</template>

<script>

import VxSidebar from "@/layouts/components/vx-sidebar/VxSidebar.vue"
import TheNavbar from "../components/TheNavbarMap.vue"
import TheFooter from "../components/TheFooter.vue"
import TheCustomizer from "../components/customizer/TheCustomizer.vue"
import themeConfig from "@/../themeConfig.js"
import sidebarItems from "@/layouts/components/vx-sidebar/sidebarItems.js"

export default {
  data () {
    return {
      navbarType: themeConfig.navbarType || "floating",
      navbarColor: themeConfig.navbarColor || "#fff",
      footerType: themeConfig.footerType || "static",
      routerTransition: themeConfig.routerTransition || "none",
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      sidebarItems: sidebarItems,
      hideScrollToTop: themeConfig.hideScrollToTop,
      disableCustomizer: themeConfig.disableCustomizer,
      windowWidth: window.innerWidth, //width of windows
    }
  },
  watch: {
    isThemeDark(val) {
      if (this.navbarColor == "#fff" && val) {
        this.updateNavbarColor("#10163a")
      } else {
        this.updateNavbarColor("#fff")
      }
    }
  },
  computed: {
    isAppPage() {
      if (this.$route.path.includes("/apps/")) return true
      else return false
    },
    isThemeDark() {
      return this.$store.state.theme == "dark"
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.sidebarWidth == "default") return "content-area-default"
      else if (this.sidebarWidth == "reduced") return "content-area-reduced"
      else if (this.sidebarWidth) return "content-area-full"
    },
    navbarClasses() {
      return {
        "navbar-hidden": this.navbarType == "hidden",
        "navbar-sticky": this.navbarType == "sticky",
        "navbar-static": this.navbarType == "static",
        "navbar-floating": this.navbarType == "floating"
      }
    },
    footerClasses() {
      return {
        "footer-hidden": this.footerType == "hidden",
        "footer-sticky": this.footerType == "sticky",
        "footer-static": this.footerType == "static"
      }
    }
  },
  methods: {
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      if (val == "#fff") this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    updateFooter(val) {
      this.footerType = val
    },
    updateRouterTransition(val) {
      this.routerTransition = val
    },
    updateNavbar(val) {
      if(val == 'static') this.updateNavbarColor("#fff")
      this.navbarType = val
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth
      this.setSidebarWidth()
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false)
        this.$store.dispatch("updateSidebarWidth", "no-sidebar")
      } else if (this.windowWidth < 1200) {
        this.$store.dispatch("updateSidebarWidth", "reduced")
      } else {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true)
      }
    },
    toggleHideScrollToTop(val) {
      this.hideScrollToTop = val
    }
  },
  components: {
    VxSidebar,
    TheNavbar,
    TheFooter,
    TheCustomizer
  },
  created () {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      const newColorScheme = e.matches ? "dark" : "light";
      this.$store.dispatch("updateTheme", newColorScheme)
    })
    this.setSidebarWidth()
    if (this.navbarColor == "#fff" && this.isThemeDark) {
      this.updateNavbarColor("#10163a")
    } else {
      this.updateNavbarColor(this.navbarColor)
    }
  },
}
</script>
<style>
  .entro {
    display: none;
  }
  body {
    padding: 0;
    margin: 0;
  }
  html, body, .map-response, .dashboard-response, #app, #content-area {
    height: 100%;
    width: 100%;
  }
  #navbar-map .vx-navbar-wrapper {
    /* background: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;  */
    /* background-color: #2c2c2c00 !important;  */
  }
  .theme-dark .vs-navbar {
    /* background: none !important; */
  }
</style>
<style scoped>
  #content-area .content-wrapper {
    min-height: auto;
  }
</style>