<template lang="pug">
  div(
    class="parentx"
  )
    vs-sidebar(
      ref="mainSidebar"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      default-index="-1"
      class="sidebarx main-menu-sidebar items-no-padding"
      v-model="isSidebarActive"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
      :reduce-not-hover-expand="true"
    )
      div(
        @mouseenter="sidebarMouseEntered"
        @mouseleave="sidebarMouseLeave"
      )
        div(
          class="header-sidebar flex items-end justify-between"
          slot="header"
        )
          div(
            class="flex items-center logo-bb"
          )
            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M38.1078 43.8294C27.9115 46.3235 17.7152 46.3235 7.59221 43.8294C5.17151 43.1693 2.45738 40.5285 1.87055 38.1078C-0.623515 27.9115 -0.623515 17.7152 1.87055 7.59221C2.53074 5.17151 5.17151 2.45738 7.59221 1.87055C17.7885 -0.623515 27.9848 -0.623515 38.1078 1.87055C40.5285 2.53074 43.2426 5.17151 43.8294 7.59221C46.3235 17.7885 46.3235 27.9848 43.8294 38.1078C43.2426 40.5285 40.6018 43.2426 38.1078 43.8294Z" fill="#FDD402"/>
              <path d="M20.5758 36.8606C28.7999 36.8606 35.4668 30.1937 35.4668 21.9696C35.4668 13.7455 28.7999 7.07861 20.5758 7.07861C12.3517 7.07861 5.68481 13.7455 5.68481 21.9696C5.68481 30.1937 12.3517 36.8606 20.5758 36.8606Z" fill="black"/>
              <path d="M38.9144 38.1077C38.7677 38.1077 38.6944 38.0343 38.5477 37.961L30.3319 32.5327C29.6717 33.0462 28.9382 33.633 28.278 34.0732C28.1313 34.1465 28.1313 34.1465 27.9846 34.1465C27.8379 34.1465 27.6178 34.0732 27.5445 33.9265L24.4636 29.8919L13.9738 16.468C13.8271 16.248 13.8271 15.9546 14.0472 15.8078C14.1939 15.6611 14.2672 15.6611 14.4873 15.6611C14.634 15.6611 14.7807 15.7345 14.8541 15.8078L27.5445 26.591C27.9846 26.0041 28.3514 25.2706 28.5714 24.6104C28.6448 24.4637 28.7915 24.2436 29.0115 24.1703C29.0849 24.1703 29.0849 24.1703 29.1583 24.1703C29.305 24.1703 29.525 24.2436 29.5984 24.3903L32.8993 28.7183L39.4279 37.1541C39.5746 37.3741 39.5746 37.6675 39.3546 37.8143C39.1345 37.961 39.0611 38.1077 38.9144 38.1077Z" fill="#FBBE02"/>
              <path d="M14.4141 16.1013L27.6179 27.2512C28.2781 26.5177 28.7916 25.5641 29.085 24.6104L32.386 28.9384L38.9146 37.5209L30.4054 31.8726C29.7452 32.5328 28.9383 33.1196 28.1314 33.5597L24.9772 29.4519L14.4141 16.1013ZM14.4141 15.001C14.1207 15.001 13.9006 15.1477 13.6805 15.3678C13.2404 15.8079 13.2404 16.3947 13.5338 16.8348L23.9502 30.3321L27.0311 34.3666C27.2512 34.66 27.5446 34.8068 27.9114 34.8068C28.1314 34.8068 28.3515 34.7334 28.4249 34.66C29.085 34.2199 29.6719 33.8531 30.2587 33.3397L38.181 38.4745C38.3277 38.6212 38.6212 38.6212 38.7679 38.6212C39.0613 38.6212 39.3547 38.4745 39.5748 38.2544C40.0149 37.8143 40.0149 37.3008 39.6481 36.7873L33.0462 28.2782L29.7452 23.9503C29.5252 23.6568 29.2318 23.5101 28.865 23.5101C28.7916 23.5101 28.7183 23.5101 28.7183 23.5101C28.2781 23.5835 28.0581 23.8769 27.838 24.1703C27.6913 24.6838 27.3979 25.1239 27.1778 25.6374L15.0743 15.3678C14.9276 15.1477 14.7075 15.001 14.4141 15.001Z" fill="black"/>
              <path d="M23.7301 30.0385C22.9232 30.4053 22.1163 30.552 21.2361 30.552C16.468 30.9922 12.4335 27.3978 11.9934 22.7031C11.8467 20.7225 12.4335 18.6686 13.5338 17.0548C13.9006 16.5413 14.194 16.1745 14.7075 15.661C16.1012 14.2673 17.8617 13.4604 19.769 13.3137C24.6104 12.8735 28.7916 16.4679 29.2317 21.236C29.3051 21.7495 29.3051 22.1162 29.3051 22.7031L33.1195 27.6178C36.2004 20.6491 33.1195 12.5068 26.1508 9.35252C19.1821 6.27162 11.1131 9.35252 8.10556 16.3212C8.10556 16.3946 8.0322 16.4679 8.0322 16.4679C4.95131 23.51 8.0322 31.6524 15.0009 34.8066C17.1282 35.6869 19.4022 36.127 21.7495 35.9803C23.5834 35.8336 25.3439 35.2467 26.9577 34.3665L23.7301 30.0385Z" fill="white"/>
            </svg>
          div(class="mb-3 ml-2" v-if="false")
            template(
              v-if="showCloseButton"
            )
              feather-icon(
                icon="XIcon"
                class="m-0 cursor-pointer"
                @click="$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)"
              )
            template(
              v-else-if="!showCloseButton && !sidebarItemsMin"
            )
              feather-icon(
                icon="DiscIcon"
                class="mr-0 cursor-pointer"
                svg-classes="stroke-current"
                v-show="!reduce"
                @click="toggleReduce(true)"
                id="btnSidebarToggler"
              )
              feather-icon(
                icon="CircleIcon"
                class="mr-0 cursor-pointer"
                svg-classes="stroke-current"
                v-show="reduce"
                @click="toggleReduce(false)"
                id="btnSidebarToggler"
              )

        div(
          class="shadow-bottom"
          v-show="showShadowBottom"
        )

        VuePerfectScrollbar(
          ref="mainSidebarPs"
          class="scroll-area--main-sidebar pt-2"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
        )
          template(
            v-for="(sidebarItem, index) in sidebarItems"
          )
            span(
              :key="`header-${index}`"
              v-if="sidebarItem.header && !sidebarItemsMin"
              class="navigation-header truncate"
            ) {{ $t(sidebarItem.i18n) || sidebarItem.header }}
            template(
              v-else-if="!sidebarItem.header"
            )
              vx-sidebar-item(
                ref="sidebarLink"
                :key="`sidebarItem-${index}`"
                v-if="!sidebarItem.submenu"
                :index="index"
                :to="sidebarItem.slug != 'external' ? sidebarItem.url : ''"
                :href="sidebarItem.slug == 'external' ? sidebarItem.url : ''"
                :packIcon="sidebarItem.iconPack"
                :svgIcon="sidebarItem.svgIcon != null ? require(`@/assets/images/icon/${sidebarItem.svgIcon}`) : null"
                :icon="sidebarItem.icon"
                :target="sidebarItem.target"
                :isDisabled="sidebarItem.isDisabled"
              )
                span(
                  v-show="!sidebarItemsMin"
                  class="truncate"
                ) {{ $t(sidebarItem.i18n) || sidebarItem.name }}
                vs-chip(
                  class="ml-auto"
                  :color="sidebarItem.tagColor"
                  v-if="sidebarItem.tag && (isMouseEnter || !reduce)"
                ) {{ sidebarItem.tag }}

              template(
                v-else
              )
                vx-sidebar-group(
                  ref="sidebarGrp"
                  :key="`group-${index}`"
                  :openHover="openGroupHover"
                  :group="sidebarItem"
                  :groupIndex="index"
                  :open="isGroupActive(sidebarItem)"
                )
        div(
          class="header-sidebar flex items-end justify-between"
          slot="footer"
        )
          div(
            class="flex-block items-center logo-bt"
          )
            img.mb-2.img-class-resp(
              v-if="logo"
              :src="logo"
              :class="switch1 == false ? 'img-dark' : '' "
              alt="logo"
            )
            vs-switch(
              v-model="switch1"
              icon-pack="feather"
              vs-icon-off="icon-x"
              vs-icon-on="icon-check"
            )
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
import VxSidebarGroup from "./VxSidebarGroup.vue"
import VxSidebarItem from "./VxSidebarItem.vue"

export default {
  name: "vx-sidebar",
  props: {
    sidebarItems: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    logo: {
      type: String
    },
    logo_letras: {
      type: String
    },
    parent: {
      type: String
    },
    openGroupHover: {
      type: Boolean,
      default: false
    },
    reduceNotRebound: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    switch1: false,
    clickNotClose: false, // disable close sidebar on outside click
    reduce: false, // determines if sidebar is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    isMouseEnter: false,
    settings: {
      // perfectscrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true
    },
    windowWidth: window.innerWidth, //width of windows
    showShadowBottom: false
  }),
  computed: {
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", val)
      }
    },
    reduceSidebar() {
      return Boolean(this.reduce && this.reduceButton)
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton
      },
      set(val) {
        this.$store.commit("TOGGLE_REDUCE_BUTTON", val)
      }
    },
    sidebarItemsMin() {
      return this.$store.state.sidebarItemsMin
    },
    isGroupActive() {
      return sidebarItem => {
        const path = this.$route.fullPath
        let open = false
        let func = function(sidebarItem) {
          if (sidebarItem.submenu) {
            sidebarItem.submenu.forEach(item => {
              if (path == item.url) {
                open = true
              } else if (item.submenu) {
                func(item)
              }
            })
          }
        }
        func(sidebarItem)
        return open
      }
    }
  },
  watch: {
    switch1(val) {
      if(val) {
        // https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png
        this.$store.dispatch("UpdateMapaUrl", "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
        this.$store.dispatch("updateTheme", "dark")
      } else {
        this.$store.dispatch("updateTheme", "light")
        this.$store.dispatch("UpdateMapaUrl", "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
      }
    },
    reduce(val) {
      if (val == true) {
        this.$store.dispatch("updateSidebarWidth", "reduced")
      } else {
        this.$store.dispatch("updateSidebarWidth", "default")
      }

      setTimeout(function() {
        window.dispatchEvent(new Event("resize"))
      }, 100)
    },
    reduceButton() {
      this.setSidebarWidth()
    },
    $route() {
      if (this.isSidebarActive && this.showCloseButton)
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false)
    }
  },
  methods: {
    sidebarMouseEntered() {
      if (this.reduce) this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false)
      this.isMouseEnter = true
    },
    sidebarMouseLeave() {
      if (this.reduce) {
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true)
      }
      this.isMouseEnter = false
    },
    toggleReduce(val) {
      this.reduceButton = val
      this.setSidebarWidth()
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth
      this.setSidebarWidth()
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 992)
          this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "md")
        else this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "lg")

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false)
        if (this.reduceButton) this.reduce = false
        // this.reduceButton = false
        this.showCloseButton = true
        this.clickNotClose = false
        this.$store.dispatch("updateSidebarWidth", "no-sidebar")
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false)
      } else {
        this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "xl")
        if (this.reduceButton) this.reduce = true
        else this.reduce = false

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true)
        if (this.reduceButton && !this.isMouseEnter)
          this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true)
        else this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false)

        this.clickNotClose = true
        this.showCloseButton = false
        if (this.reduceSidebar)
          this.$store.dispatch("updateSidebarWidth", "reduced")
        else this.$store.dispatch("updateSidebarWidth", "default")
      }
    },
    psSectionScroll() {
      if (this.$refs.mainSidebarPs.$el.scrollTop > 0)
        this.showShadowBottom = true
      else this.showShadowBottom = false
    }
  },
  components: {
    VxSidebarGroup,
    VxSidebarItem,
    VuePerfectScrollbar
  },
  mounted() {
    let colorSchemeQueryList2 = window.matchMedia('(prefers-color-scheme: dark)');

    const setColorScheme2 = e => {
      if (e.matches) {
        // Dark
        this.switch1=true
        this.$store.dispatch("UpdateMapaUrl", "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png")
      } else {
        // Light
        this.switch1=false
        this.$store.dispatch("UpdateMapaUrl", "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png")
      }
    }
    setColorScheme2(colorSchemeQueryList2);
    colorSchemeQueryList2.addListener(setColorScheme2);
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize)
    })
    this.setSidebarWidth()
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize)
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxSidebar.scss"
</style>

<style lang="css">
  .vs-sidebar-group .vs-sidebar-group-items .vs-sidebar--item a {
    padding: 4px 15px 10px 20px;
  }
</style>
<style>
.img-dark {
  /* filter: brightness(0); */
}
.img-class-resp {
  width: 25px;
  margin: 7px;
}
.mr-666 {
  margin-right: 1.5rem;
}
.logo-bb {
  padding-bottom: 15px;
  border-bottom: 1px solid;
}
.logo-bt {
  width: 46px;
  padding-top: 0px;
  border-top: 1px solid;
  margin-bottom: 10px;
}
div.vs-sidebar--item > a {
  filter: invert(1);
}
</style>

